/* tslint:disable */
/* eslint-disable */
/**
 * AfterMeal API Admin Docs
 * 에프터밀 관리자 API 엔드포인트(Endpoint)와 객체 정보, 파라미터, 요청 및 응답 예제를 살펴보세요. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ActivityCreationRequestDto
 */
export interface ActivityCreationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityCreationRequestDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityCreationRequestDto
     */
    'maxParticipants': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreationRequestDto
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreationRequestDto
     */
    'type': ActivityCreationRequestDtoType;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreationRequestDto
     */
    'scheduledDate': string;
}

export const ActivityCreationRequestDtoType = {
    Lunch: 'LUNCH',
    Dinner: 'DINNER'
} as const;

export type ActivityCreationRequestDtoType = typeof ActivityCreationRequestDtoType[keyof typeof ActivityCreationRequestDtoType];


